<template>
  <!--srcviewscompanydetailsBusinessActivitiesSupplyChainvuehtmlStart-->

  <div id="defaultId1 " style="padding-bottom:20px">
    <div id="g2ede8" class="operations-content">
      <h2 id="gf7b16" class="operations-head detail_title_20 margin-bottom20">
  
        
        <div>
        
          <span class="operations-headspan">
            Trade Trend for this Company
            </span>
       
          <el-date-picker v-model="datePicker"  @change="SelectPicker" style="width: 20rem;height: 3.571428rem; margin-left: 40px;"
          type="monthrange" range-separator="" start-placeholder="Start date" value-format="yyyy-MM"
          end-placeholder="End date"></el-date-picker>
        </div>
        <div
          class="perations-content-left">
          <span :class="activeIEports == '1'
            ? 'perations-button perations-button-active'
            : 'perations-button'
            " @click="ceckIEports('1')">Imports</span>
          <span @click="ceckIEports('2')" :class="activeIEports == '2'
            ? 'perations-button perations-button-active'
            : 'perations-button'
            ">Exports</span>
        </div>
      </h2>
      <div>
    
      </div>
      <div class="operations-box">
        <div ref="SupplyChainEchart" style="width: 100%; height: 642px"></div>
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>

<script>
import { tradeTrends, } from '@/api/companyApi.js';
import ReLogin from "../../../../components/ReLogin.vue";
export default {
  name: "",
  // props: {},
  components: {
    ReLogin,
  },
  data() {
    return {
      data: {
        links: [],
        nodes: [],
      },
      datePicker: [
        '2024-01', '2024-12'],

      activeIEports: '1',
      importTrendListEcharts: [],


      reLoginVis: false,

    };
  },
  mounted() {
    this.getData();

  },
  methods: {
    SelectPicker() {
      this.getData()
    },
    ceckIEports(row) {
      this.activeIEports = row;
      this.getData()
    },
    default() { },
    getData() {
      tradeTrends({
        aaaId: this.$route.query.id3a,
        startDate: this.datePicker[0],
        type: this.activeIEports,
        endDate: this.datePicker[1]
      }).then(res => {
        if (res.code == 200) {
          this.importTrendListEcharts = res.data
          this.suppleEchart();
        }
      })

    },
    suppleEchart() {
      let that = this;
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',

          }
        },
        grid: {
          left: '0%', // 设置左侧边距
          right: '0%',
          top: '5%',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          right: '10px',
          top: '0px',
          //  width: '200px'
          // 其他图例配置项
          // ...
        },
        xAxis: {
          type: 'category',
          data: that.importTrendListEcharts.map(item => item.impYearMonth          ),
          axisTick: {
            show: false // 不显示 x 轴上的间隔点
          },
          axisLabel: {
              fontSize: 14,
            color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' ,// 字体类型,
            }
          
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
              // 横坐标轴颜色
              lineStyle: {
                width: 1,

                color: '#8497AB'
              }
            },
            // 背景虚线
            splitLine: {
              lineStyle: {
                width: 1,
                type: 'dashed',
                color: '#8398BB'
              }
            },
            axisLabel: {
              fontSize: 14,
            color: '#1A2332', // 设置字体颜色
            fontSize: 14, // 可以在这里设置字体大小
            fontWeight: 'normal', // 字体粗细
            fontFamily: 'DMSans, DMSans' ,// 字体类型,
              formatter: '{value} '
            }
          },
          // {
          //   type: 'value',
          //   position: 'right',
          //   alignTicks: true,
          //   min: -100,
          //   max: 100,
          //   interval: 20,
          //   axisLabel: {
          //     formatter: '{value} %'
          //   }

          // }
        ],
        series: [
          {
            name: 'Transactions',
            type: 'bar',
            barWidth: '60',
            // barCategoryGap: '20', // 控制同类柱子之间的间距

            itemStyle: { color: '#009EA7' },
            emphasis: {
              itemStyle: {
                color: '#009EA7',
              },
            },
            data: that.importTrendListEcharts.map(item => item.transactions)
          },
          

        ]
      };
      this.echartsBox.setOption(option);
      this.$nextTick(()=>{
        this.echartsBox.resize();
      })

    },
  },
  computed: {},

};
</script>
<style scoped>
.operations-content {
  padding: 30px 40px;
  height: 100%;
}

.operations-head {
  font-size: 30px;
  font-family: 'DMSans Bold';
  /* font-family: "Arial Bold"; */
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.operations-content {
  padding: 30px 40px;
  background: #FFFFFF;
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.table-content-color {
  color: #009EA7;
}





/deep/ .el-range-editor.el-input__inner {
  padding: 0px !important;
}

/*cssStart*/

/*cssEnd*/
</style>